import React from "react";

const FooterText = () => {

    return (
        <div class="footer_text">Linley and Simpson Group Ltd is registered in England &amp; Wales.<br />
        Registered office address: Troy Mills, Troy Road, Horsforth, Leeds, LS18 5GN.<br />
        Registered company number: 08761192. VAT Number: 299705053.</div>
    )
}
export default FooterText;